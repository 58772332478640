import React from "react";
import { useAdmin } from "../../../context/AdminContext"; // Import your AdminContext hook

const AdminLightCustomers = () => {
  const { lightCustomersData } = useAdmin(); // Use AdminContext to get the data

  // If there's no data, show a message (this would only happen if the context was not populated yet)
  if (!lightCustomersData) {
    return <div>Everybody up to date</div>;
  }

  // Display the data in a table
  return (
    <div style={{ padding: "20px" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Contratos sin facturas recientes (1 mes)</h1>
      <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px" }}>
          <thead>
            <tr style={{ backgroundColor: "#f4f4f4" }}>
              <th style={headerCellStyle}>CUPS</th>
              <th style={headerCellStyle}>Email</th>
              <th style={headerCellStyle}>Name</th>
              <th style={headerCellStyle}>First Surname</th>
              <th style={headerCellStyle}>Second Surname</th>
              <th style={headerCellStyle}>Address Street</th>
              <th style={headerCellStyle}>Address Number</th>
              <th style={headerCellStyle}>Latest Data Date</th>
              <th style={headerCellStyle}>Offer URL</th>
            </tr>
          </thead>
          <tbody>
            {lightCustomersData.map((customer, index) => (
              <tr key={index} style={index % 2 === 0 ? rowEvenStyle : rowOddStyle}>
                <td style={cellStyle}>{customer.cups}</td>
                <td style={cellStyle}>{customer.email}</td>
                <td style={cellStyle}>{customer.name}</td>
                <td style={cellStyle}>{customer.first_surname}</td>
                <td style={cellStyle}>{customer.second_surname}</td>
                <td style={cellStyle}>{customer.address_street}</td>
                <td style={cellStyle}>{customer.address_number}</td>
                <td style={cellStyle}>{customer.latest_data_date}</td>
                <td style={cellStyle}>
                  {customer.offer_url ? (
                    <a href={customer.offer_url} target="_blank" rel="noopener noreferrer">
                      offer url
                    </a>
                  ) : (
                    "No URL"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const headerCellStyle = {
  padding: "10px",
  textAlign: "left",
  fontWeight: "bold",
  border: "1px solid #ddd",
};

const cellStyle = {
  padding: "10px",
  textAlign: "left",
  border: "1px solid #ddd",
};

const rowEvenStyle = {
  backgroundColor: "#f9f9f9",
};

const rowOddStyle = {
  backgroundColor: "#ffffff",
};

export default AdminLightCustomers;
