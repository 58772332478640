export const ROUTES = {
  ROOT: '*',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',

  ACCOUNT_WAITLIST: '/account/waitlist',
  ACCOUNT_EMAIL: '/account/email',
  ACCOUNT_PASSWORD: '/account/new-password',
  ACCOUNT_ADDRESS: '/account/address',
  ACCOUNT_NEW_TYPE: '/account/new-type',
  ACCOUNT_SUCCESS: '/account/success',
  ACCOUNT_AUTH_SIGN: '/account/auth-sign',
  ACCOUNT_IBAN: '/account/iban',
  ACCOUNT_NEW_USER: '/account/new-user',
  ACCOUNT_ESTIMATED_RESULTS: '/account/estimated-results',

  ADMIN: '/admin',
  ADMIN_OPS: '/admin/*',
  LATE_LIGHT_BILLS: '/late-light-bills',
  CONTRACTS_TO_ONBOARD: '/contracts-to-onboard',
  ADD_NEW_BILLS: '/add-new-bills',
  INVITE_NEW_USERS: '/invite-new-users',
  GENERAL_STATUS: '/general-status',
};
