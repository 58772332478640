import React, { useState } from "react";

import services from "../../../services";

const {
  GenerationInvitation,
} = services;

const InviteNewUser = () => {
  const [email_to_invite, setEmail] = useState('');
  const [tempPassword, setTempPassword] = useState('');

  const handleSend = async () => {
    try {
      const response = await GenerationInvitation(email_to_invite)
      setTempPassword(response.temp_password);
    } catch (error) {
      console.error("Error inviting user:", error);
    }
  };

  return (
    <div>
      <h1>Invite a un nuevo usuario</h1>
      <div>
        <input
          type="email"
          value={email_to_invite}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter user's email"
          style={{ padding: "8px", marginRight: "10px", width: "300px" }}
        />
        <button onClick={handleSend} style={{ padding: "8px 16px" }}>
          Send
        </button>
      </div>
      {tempPassword && (
        <div style={{ marginTop: "20px", color: "green" }}>
          <p>Contraseña temporal: <strong>{tempPassword}</strong></p>
        </div>
      )}
    </div>
  );
};

export default InviteNewUser;
