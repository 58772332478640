import React, { useState, useEffect } from 'react';
import { Field, ErrorMessage } from 'formik';

import useTranslations from '../../i18n/useTranslations';

import styles from './FormikCheckbox.module.css';

const FormikCheckbox = ({
  label,
  name,
  error = null,
  touched = false,
  disabled = false,
  isCenter = false,
  ...rest
}) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const { t } = useTranslations();

  useEffect(() => {
    setIsInvalid(error && touched ? true : false);
  }, [error, touched]);

  return (
    <div>
      <div
        className={`${styles.root} ${disabled ? styles.isDisabled : ''} ${
          isInvalid ? styles.hasError : ''
        } ${isCenter ? styles.center : ''}`}
      >
        <Field
          className={styles.input}
          id={name}
          name={name}
          type="checkbox"
          disabled={disabled}
          {...rest}
        />
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      </div>
      <ErrorMessage
        name={name}
        render={(msg) => <span className={styles.errorMsg}>{t(msg)}</span>}
      />
    </div>
  );
};

export default FormikCheckbox;
