import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';
import { useForm } from '../../../context';
import services from '../../../services';
import { PASSWORD_FORM_INPUTS } from '../../../constants';
import { accountNewPasswordSchema } from '../../../validationSchemas';

import { PublicLayout } from '../../../Layout';
import { FormikInput } from '../../Input';
import { Button } from '../../Button';
import stylesForm from '../../Form/Form.module.css';
import styles from './AccountPassword.module.css';

const { createNewPassword, isIBANRegistered, generateDropboxSignUrl, isUserOnboarded } = services;

const PasswordRequirements = ({ passwordValidations }) => {
  const { t } = useTranslations();

  return (
    <ul className={styles.requirements}>
      <li className={passwordValidations.minLength ? styles.isValid : ''}>
        {t('input:confirm_password:requirements:min_length')}
      </li>
      <li className={passwordValidations.uppercase ? styles.isValid : ''}>
        {t('input:confirm_password:requirements:uppercase')}
      </li>
      <li className={passwordValidations.specialChar ? styles.isValid : ''}>
        {t('input:confirm_password:requirements:special_char')}
      </li>
    </ul>
  );
};

const AccountPassword = () => {
  const { formData, updateFormData, setSignUrl } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslations();

  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    uppercase: false,
    specialChar: false,
  });

  const handleNextStep = async (values) => {
    try {
      const updatedFormData = { ...formData, new_password: values.new_password };

      updateFormData(updatedFormData);

      const res = await createNewPassword(updatedFormData);
      if (!res.email_registered) {
        navigate(ROUTES.ACCOUNT_NEW_TYPE);
        return;
      }

      const [resIbanRegistered, resUserOnboarded] = await Promise.all([
        isIBANRegistered(updatedFormData),
        isUserOnboarded(updatedFormData),
      ]);

      if (resIbanRegistered.success && !resUserOnboarded.success) {
        const dropboxSignUrl = await generateDropboxSignUrl(updatedFormData);
        setSignUrl(dropboxSignUrl.sign_url);
        navigate(ROUTES.ACCOUNT_AUTH_SIGN);
      } else {
        navigate(ROUTES.ACCOUNT_ADDRESS);
      }
    } catch (error) {
      console.error(t('general:register:error'), error);
    }
  };

  const FormikPasswordValidator = () => {
    const { values } = useFormikContext();

    React.useEffect(() => {
      const password = values.new_password || '';
      const newValidations = {
        minLength: password.length >= 8,
        uppercase: /[A-Z]/.test(password),
        specialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password),
      };

      if (JSON.stringify(newValidations) !== JSON.stringify(passwordValidations)) {
        setPasswordValidations(newValidations);
      }
    }, [values.new_password]);

    return null;
  };

  return (
    <PublicLayout>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('account_new_password:heading')}</h1>
        </header>
        <div className={styles.content}>
          <Formik
            initialValues={PASSWORD_FORM_INPUTS}
            validationSchema={accountNewPasswordSchema}
            onSubmit={handleNextStep}
          >
            {({ errors, touched }) => (
              <>
                <FormikPasswordValidator />
                <Form className={stylesForm.form} noValidate>
                  <div className={stylesForm.formGroup}>
                    <div>
                      <FormikInput
                        type="password"
                        label="input:new_password"
                        name="new_password"
                        error={errors['new_password']}
                        touched={touched['new_password']}
                        inputMode="text"
                        required
                      />
                    </div>
                    <div>
                      <FormikInput
                        type="password"
                        label="input:confirm_password"
                        name="confirm_new_password"
                        error={errors['confirm_new_password']}
                        touched={touched['confirm_new_password']}
                        inputMode="text"
                        required
                      />
                    </div>
                    <PasswordRequirements passwordValidations={passwordValidations} />
                  </div>
                  <div className={stylesForm.formActions}>
                    <Button isFullWidth type="submit">
                      {t('action:continue')}
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </section>
    </PublicLayout>
  );
};

export default AccountPassword;
