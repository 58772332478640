import { Routes, Route } from 'react-router-dom';

import { ROUTES } from '../../routes';

import { Login } from '../Login';
import { AccountEmail } from './AccountEmail';
import { AccountPassword } from './AccountPassword';
import { AccountWaitlist } from './AccountWaitlist';
import { AccountAddress } from './AccountAddress';
import { AccountType } from './AccountType';
import { AccountSuccess } from './AccountSuccess';
import { AccountIBAN } from './AccountIBAN';
import { AccountSign } from './AccountSign';
import { AccountNewUser } from './AccountNewUser';
import { AccountEstimatedResults } from './AccountEstimatedResults';

const Onboarding = () => (
  <Routes>
    <Route index element={<Login />} />
    <Route path={ROUTES.ACCOUNT_EMAIL} element={<AccountEmail />} />
    <Route path={ROUTES.ACCOUNT_WAITLIST} element={<AccountWaitlist />} />
    <Route path={ROUTES.ACCOUNT_PASSWORD} element={<AccountPassword />} />
    <Route path={ROUTES.ACCOUNT_NEW_TYPE} element={<AccountType />} />
    <Route path={ROUTES.ACCOUNT_NEW_USER} element={<AccountNewUser />} />
    <Route path={ROUTES.ACCOUNT_ADDRESS} element={<AccountAddress />} />
    <Route path={ROUTES.ACCOUNT_ESTIMATED_RESULTS} element={<AccountEstimatedResults />} />
    <Route path={ROUTES.ACCOUNT_IBAN} element={<AccountIBAN />} />
    <Route path={ROUTES.ACCOUNT_AUTH_SIGN} element={<AccountSign />} />
    <Route path={ROUTES.ACCOUNT_SUCCESS} element={<AccountSuccess />} />
  </Routes>
);

export default Onboarding;
