import { useCallback } from 'react';
import { useIntl } from 'react-intl';

const useTranslations = () => {
  const intl = useIntl();

  const formatMessage = useCallback(
    (key, values = {}) => intl.formatMessage({ id: key, defaultMessage: key }, values),
    [intl]
  );

  return {
    t: formatMessage,
    locale: intl.locale,
  };
};

export default useTranslations;
