import styles from './SocialLogin.module.css';

const SocialLogin = () => {
  return (
    <>
      <span className={styles.divider} aria-hidden />
      <div className={styles.socialButtons} inert="true" tabIndex="-1">
        <button>
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.3539 0C14.4004 0 14.447 0 14.4962 0C14.6103 1.41044 14.072 2.46432 13.4177 3.22751C12.7757 3.98542 11.8966 4.7205 10.4747 4.60897C10.3799 3.21872 10.9191 2.24301 11.5725 1.48158C12.1785 0.771967 13.2895 0.140517 14.3539 0Z"
              fill="black"
            />
            <path
              d="M18.6568 14.6803C18.6568 14.6944 18.6568 14.7067 18.6568 14.7198C18.2572 15.9301 17.6873 16.9672 16.9917 17.9298C16.3567 18.8036 15.5786 19.9796 14.1893 19.9796C12.9887 19.9796 12.1913 19.2076 10.9609 19.1865C9.65934 19.1655 8.94358 19.832 7.75357 19.9998C7.61745 19.9998 7.48132 19.9998 7.34783 19.9998C6.47399 19.8733 5.76877 19.1813 5.255 18.5577C3.74005 16.7152 2.56936 14.3352 2.35156 11.2895C2.35156 10.9909 2.35156 10.6932 2.35156 10.3946C2.44378 8.21478 3.50293 6.4425 4.91073 5.58359C5.65372 5.12691 6.6751 4.73785 7.81241 4.91174C8.29983 4.98727 8.79779 5.15414 9.23427 5.31924C9.64792 5.4782 10.1652 5.76012 10.6553 5.74519C10.9872 5.73553 11.3174 5.56251 11.6521 5.44044C12.6322 5.08651 13.5929 4.68077 14.8594 4.87135C16.3813 5.10144 17.4616 5.77768 18.129 6.82102C16.8415 7.64041 15.8237 8.87521 15.9975 10.9838C16.1521 12.8993 17.2657 14.0199 18.6568 14.6803Z"
              fill="black"
            />
          </svg>
        </button>
        <button>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.799 10.2083C18.799 9.55825 18.7407 8.93325 18.6324 8.33325H9.99902V11.8833H14.9324C14.7157 13.0249 14.0657 13.9916 13.0907 14.6416V16.9499H16.0657C17.799 15.3499 18.799 12.9999 18.799 10.2083Z"
              fill="#4285F4"
            />
            <path
              d="M9.99876 19.1664C12.4738 19.1664 14.5488 18.3498 16.0654 16.9498L13.0904 14.6414C12.2738 15.1914 11.2321 15.5248 9.99876 15.5248C7.61543 15.5248 5.59043 13.9164 4.86543 11.7498H1.81543V14.1164C3.32376 17.1081 6.41543 19.1664 9.99876 19.1664Z"
              fill="#34A853"
            />
            <path
              d="M4.86536 11.7419C4.68203 11.1919 4.5737 10.6086 4.5737 10.0003C4.5737 9.39194 4.68203 8.8086 4.86536 8.2586V5.89194H1.81536C1.19036 7.12527 0.832031 8.51694 0.832031 10.0003C0.832031 11.4836 1.19036 12.8753 1.81536 14.1086L4.19036 12.2586L4.86536 11.7419Z"
              fill="#FBBC05"
            />
            <path
              d="M9.99876 4.48325C11.3488 4.48325 12.5488 4.94992 13.5071 5.84992L16.1321 3.22492C14.5404 1.74159 12.4738 0.833252 9.99876 0.833252C6.41543 0.833252 3.32376 2.89159 1.81543 5.89159L4.86543 8.25825C5.59043 6.09159 7.61543 4.48325 9.99876 4.48325Z"
              fill="#EA4335"
            />
          </svg>
        </button>
        <button>
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.7718 10.652H7.96412V19.6774C7.96412 19.8556 8.10851 20 8.2867 20H12.0039C12.1821 20 12.3264 19.8556 12.3264 19.6774V10.6945H14.8467C15.0106 10.6945 15.1484 10.5715 15.1672 10.4088L15.5499 7.08606C15.5604 6.99465 15.5315 6.9031 15.4703 6.83452C15.4091 6.76587 15.3215 6.72658 15.2295 6.72658H12.3266V4.64374C12.3266 4.01587 12.6646 3.69748 13.3315 3.69748C13.4265 3.69748 15.2295 3.69748 15.2295 3.69748C15.4077 3.69748 15.5521 3.55303 15.5521 3.3749V0.324968C15.5521 0.146774 15.4077 0.0023871 15.2295 0.0023871H12.6137C12.5953 0.00148387 12.5543 0 12.4939 0C12.0401 0 10.4624 0.0890967 9.21625 1.23555C7.83548 2.506 8.02741 4.02716 8.07328 4.2909V6.72652H5.7718C5.59361 6.72652 5.44922 6.8709 5.44922 7.0491V10.3294C5.44922 10.5075 5.59361 10.652 5.7718 10.652Z"
              fill="#1877F2"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export default SocialLogin;
