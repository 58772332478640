import { Formik, Form } from 'formik';

import useTranslations from '../../../i18n/useTranslations';

import { WAITLIST_FORM_INPUTS } from '../../../constants';
import { accountWaitlistSchema } from '../../../validationSchemas';

import { PublicLayout } from '../../../Layout';
import { FormikInput } from '../../Input';
import { Button } from '../../Button';
import stylesForm from '../../Form/Form.module.css';
import styles from './AccountWaitlist.module.css';
import { ROUTES } from '../../../routes';

import services from '../../../services'; 
import { useNavigate } from 'react-router-dom';


const { addEmailToWaitlist } = services; 

const AccountWaitlist = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();


  const handleSubmit = async (values) => {
    try {
      const response = await addEmailToWaitlist(values.email);
      navigate(`${ROUTES.ROOT}`);
    } catch (error) {
      console.error('Failed to add email to waitlist:', error);
    }
  };

  return (
    <PublicLayout>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('account_waitlist:heading')}</h1>
          <p className={styles.subheading}>{t('account_waitlist:subheading')}</p>
        </header>
        <div className={styles.content}>
          <Formik
            initialValues={WAITLIST_FORM_INPUTS}
            validationSchema={accountWaitlistSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className={stylesForm.form} noValidate>
                <div className={stylesForm.formGroup}>
                  <div>
                    <FormikInput
                      label="input:email"
                      name="email"
                      error={errors['email']}
                      touched={touched['email']}
                      required
                    />
                  </div>
                </div>
                <div className={stylesForm.formActions}>
                  <Button isFullWidth type="submit">
                    {t('action:continue')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </PublicLayout>
  );
};

export default AccountWaitlist;
