import React from "react";
import { useAdmin } from "../../../context/AdminContext"; // Import your AdminContext hook

const GeneralStatus = () => {
  const { generalStatus } = useAdmin(); // Use AdminContext to get the data

  // If there's no data, show a message
  if (!generalStatus || generalStatus.length === 0) {
    return <div>Everything up to date</div>;
  }

  const data = generalStatus[0]; // Extract the first object from the array

  // Display the data in a table
  return (
    <div style={{ padding: "20px" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>General Status</h1>
      <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px" }}>
          <thead>
            <tr style={{ backgroundColor: "#f4f4f4" }}>
              <th style={headerCellStyle}>Onboarded Users</th>
              <th style={headerCellStyle}>Non-Onboarded Users</th>
              <th style={headerCellStyle}>Contracts with IBAN</th>
              <th style={headerCellStyle}>Contracts without IBAN</th>
              <th style={headerCellStyle}>Contracts to Onboard</th>
              <th style={headerCellStyle}>Contracts without Recent Bill</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={cellStyle}>{data.n_onboarded_users}</td>
              <td style={cellStyle}>{data.n_non_onboarded_users}</td>
              <td style={cellStyle}>{data.n_contracts_with_iban}</td>
              <td style={cellStyle}>{data.n_contracts_without_iban}</td>
              <td style={cellStyle}>{data.n_contracts_to_onboard}</td>
              <td style={cellStyle}>{data.n_contracts_without_recent_bill}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const headerCellStyle = {
  padding: "10px",
  textAlign: "left",
  fontWeight: "bold",
  border: "1px solid #ddd",
};

const cellStyle = {
  padding: "10px",
  textAlign: "left",
  border: "1px solid #ddd",
};

export default GeneralStatus;
