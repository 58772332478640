import { Link } from 'react-router-dom';

import styles from './Link.module.css';

export const CustomLink = ({ children, ...props }) => {
  return (
    <Link className={styles.root} {...props}>
      {children}
    </Link>
  );
};
