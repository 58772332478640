import { Navigate } from 'react-router-dom';

import { useAuth } from '../context';
import { ROUTES } from '../routes';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to={ROUTES.LOGIN} replace />;
};

export default PrivateRoute;
