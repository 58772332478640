import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';
import services from '../../../services';
import { useForm } from '../../../context';
import { ACCOUNT_ADDRESS_FORM_INPUTS } from '../../../constants';
import { accountAddressSchema } from '../../../validationSchemas';

import { FormikInput, RadioButtonGroup, RadioButton } from '../../Input';
import { Button } from '../../Button';
import { Stepper } from '../Stepper';
import { UploadFile } from './UploadFile';
import { AddButton } from './AddButton';
import { RemoveButton } from './RemoveButton';
import { Callout } from './Callout';
import { Feedback } from './Feedback';

import stylesForm from '../../Form/Form.module.css';
import styles from './AccountAddress.module.css';

const { saveUserContracts, isIBANRegistered } = services;

const formatAddress = (address) => {
  const {
    address_street,
    address_number,
    address_stair,
    address_floor_number,
    address_door_number,
  } = address;

  return [address_street, address_number, address_stair, address_floor_number, address_door_number]
    .filter(Boolean)
    .join(', ');
};

const AccountAddress = () => {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { formData, setContractResult } = useForm();
  const [countdownTime, setCountdownTime] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslations();

  const uploadDocumentRef = useRef(null);

  const handleReset = () => {
    if (uploadDocumentRef.current) {
      uploadDocumentRef.current.resetFiles();
    }
  };

  const addAddress = (formValues) => {
    setAddresses((prevAddresses) => [...prevAddresses, { ...formValues }]);
  };

  const removeAddress = (index) => {
    setAddresses((prevAddresses) => prevAddresses.filter((_, i) => i !== index));
  };

  const handleAddAddress = async (values, validateForm, setFieldError, setTouched, resetForm) => {
    const formErrors = await validateForm();
    if (Object.keys(formErrors).length === 0) {
      addAddress(values);
      resetForm();
      handleReset();
    } else {
      const touchedFields = {};
      Object.entries(formErrors).forEach(([field, message]) => {
        setFieldError(field, message);
        touchedFields[field] = true;
      });
      setTouched(touchedFields);
    }
  };

  const handleFileUpload = (e, setFieldValue) => {
    const files = e.target.files || [];
    if (files.length > 0) {
      setFieldValue('file', files);
    }
  };

  const handleNextStep = async (values) => {
    const updatedAddresses = [...addresses, values];
    const billsNForm = { ...formData, facturas: updatedAddresses };

    const numberOfFiles = updatedAddresses.reduce(
      (acc, address) => acc + (address.file ? address.file.length : 0),
      0
    );

    const secondsToWait = numberOfFiles * 15;
    setCountdownTime(secondsToWait);
    setLoading(true);

    try {
      const res = await saveUserContracts(billsNForm);
      setLoading(false);
      setCountdownTime(null);
      setContractResult(res);
      navigate(ROUTES.ACCOUNT_ESTIMATED_RESULTS);
    } catch (err) {
      setLoading(false);
      setCountdownTime(null);
      setError(true);
      const resIban = await isIBANRegistered(formData);
      if (resIban.success) {
        navigate(ROUTES.ACCOUNT_ESTIMATED_RESULTS);
      } else {
        navigate(ROUTES.ACCOUNT_ESTIMATED_RESULTS);
      }
    }
  };

  return (
    <Stepper currentStep={2}>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>
            {loading
              ? t('account_address:loading_heading')
              : error
              ? t('account_address:error_heading')
              : t('account_address:heading')}
          </h1>
        </header>
        <div className={styles.content}>
          {loading ? (
            <Feedback type="loading" countdownTime={countdownTime} />
          ) : error ? (
            <Feedback type="error" text={t('account_address:error_text')} />
          ) : (
            <>
              {addresses.length > 0 && (
                <div className={styles.records}>
                  {addresses.map((address, index) => (
                    <React.Fragment key={`${address.address_street}-${address.address_number}`}>
                      {address.user_is_contract_holder === 'false' ? (
                        <Callout name={`${formData.name} ${formData.first_surname}`} />
                      ) : null}
                      <RemoveButton
                        key={index}
                        heading={formatAddress(address)}
                        text={`${formData.name} ${formData.first_surname} ${formData.second_surname}`}
                        handleClick={() => removeAddress(index)}
                      />
                    </React.Fragment>
                  ))}
                </div>
              )}

              <Formik
                initialValues={ACCOUNT_ADDRESS_FORM_INPUTS}
                validationSchema={accountAddressSchema}
                onSubmit={handleNextStep}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  setFieldError,
                  setTouched,
                  validateForm,
                  resetForm,
                }) => (
                  <Form className={stylesForm.form} noValidate>
                    <div className={stylesForm.formGroup}>
                      <fieldset className={`${stylesForm.fieldset} ${stylesForm.fieldsetGrid}`}>
                        <div>
                          <FormikInput
                            label="input:address_street"
                            name="address_street"
                            error={errors['address_street']}
                            touched={touched['address_street']}
                            required
                          />
                        </div>
                        <div>
                          <FormikInput
                            label="input:address_number"
                            name="address_number"
                            error={errors['address_number']}
                            touched={touched['address_number']}
                            inputMode="numeric"
                            required
                          />
                        </div>
                        <div>
                          <FormikInput
                            label="input:address_stair"
                            name="address_stair"
                            error={errors['address_stair']}
                            touched={touched['address_stair']}
                            required
                          />
                        </div>
                        <div>
                          <FormikInput
                            label="input:address_floor_number"
                            name="address_floor_number"
                            error={errors['address_floor_number']}
                            touched={touched['address_floor_number']}
                            required
                          />
                        </div>
                        <div>
                          <FormikInput
                            label="input:address_door_number"
                            name="address_door_number"
                            error={errors['address_door_number']}
                            touched={touched['address_door_number']}
                            required
                          />
                        </div>
                        <div>
                          <FormikInput
                            label="input:city"
                            name="city"
                            error={errors['city']}
                            touched={touched['city']}
                            required
                          />
                        </div>
                        <div>
                          <FormikInput
                            label="input:zip_code"
                            name="zip_code"
                            error={errors['zip_code']}
                            touched={touched['zip_code']}
                            required
                          />
                        </div>
                      </fieldset>
                      <fieldset className={stylesForm.fieldset}>
                        <UploadFile
                          ref={uploadDocumentRef}
                          name="file"
                          error={errors['file']}
                          touched={touched['file']}
                          handleFileUpload={(e) => handleFileUpload(e, setFieldValue)}
                          setError={(message) => setFieldError('file', message)}
                          setTouched={setTouched}
                        />
                      </fieldset>
                      <fieldset className={stylesForm.fieldset}>
                        <RadioButtonGroup
                          id="user_is_contract_holder"
                          label="input:user_is_contract_holder"
                          name="user_is_contract_holder"
                          error={errors['user_is_contract_holder']}
                          touched={touched['user_is_contract_holder']}
                        >
                          <Field
                            component={RadioButton}
                            name="user_is_contract_holder"
                            id="true"
                            label={t('option:yes')}
                          />
                          <Field
                            component={RadioButton}
                            name="user_is_contract_holder"
                            id="false"
                            label={t('option:no')}
                          />
                        </RadioButtonGroup>
                      </fieldset>
                    </div>
                    <div className={styles.formActions}>
                      <AddButton
                        onclick={() =>
                          handleAddAddress(
                            values,
                            validateForm,
                            setFieldError,
                            setTouched,
                            resetForm
                          )
                        }
                      >
                        {t('account_address:add_address')}
                      </AddButton>
                      <Button isFullWidth type="submit">
                        {t('action:continue')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
      </section>
    </Stepper>
  );
};

export default AccountAddress;
