import React, { useState, useEffect } from 'react';
import { Field, ErrorMessage } from 'formik';

import useTranslations from '../../i18n/useTranslations';

import styles from './Input.module.css';

const FormikInput = ({
  label,
  type = 'text',
  id,
  name,
  error = null,
  touched = false,
  disabled = false,
  ...rest
}) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslations();

  useEffect(() => {
    setIsInvalid(error && touched ? true : false);
  }, [error, touched]);

  return (
    <div
      className={`${styles.root} ${disabled ? styles.isDisabled : ''} ${
        isInvalid ? styles.hasError : ''
      } ${isFocused ? styles.isFocused : ''}`}
    >
      <label htmlFor={id} className={styles.label}>
        {t(label)}
      </label>
      <Field
        className={styles.input}
        id={id}
        name={name}
        type={type}
        placeholder={''}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        disabled={disabled}
        {...rest}
      />
      <ErrorMessage
        name={name}
        render={(msg) => <span className={styles.errorMsg}>{t(msg)}</span>}
      />
    </div>
  );
};

export default FormikInput;
