const availableLocales = [
  {
    localeCode: 'es-ES',
    match: /es/,
    messages: require('./messages/es-ES.json'),
  },
  {
    localeCode: 'en',
    match: /en/,
    messages: require('./messages/en.json'),
  },
];

const findBestAvailableLanguage = (language = undefined) => {
  if (language !== undefined) {
    const preferredLocale = availableLocales.find(({ match }) => match.test(language));

    if (preferredLocale) return preferredLocale;
  }

  // Fall back to the first defined locale
  console.warn('No default locale provided; falling back to the first defined locale');
  return availableLocales[0];
};

export const getIntlConfig = (language = undefined) => {
  const locale = findBestAvailableLanguage(language);

  if (locale.messages == null) {
    throw new Error(`Locale ${locale} not found`);
  }

  return {
    locale: locale.localeCode,
    messages: locale.messages,
  };
};
