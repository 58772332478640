import { useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import { getIntlConfig } from './intl';

const DEFAULT_LOCALE = 'es';

const AppIntlProvider = ({ children }) => {
  const { locale, messages } = useMemo(() => getIntlConfig(DEFAULT_LOCALE), []);

  return (
    <IntlProvider messages={messages} locale={locale}>
      {children}
    </IntlProvider>
  );
};

export default AppIntlProvider;
