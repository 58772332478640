import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import services from "../../../services";
import "./AddNewBills.module.css";

const {
  EstimateNewNOnboardedBills,
} = services;

const MAX_TOTAL_SIZE_MB = 10;
const MAX_TOTAL_SIZE_BYTES = MAX_TOTAL_SIZE_MB * 1024 * 1024;

const AddNewBills = () => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [result, setResult] = useState(null);

  const onDrop = (acceptedFiles) => {
    const totalSize = acceptedFiles.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > MAX_TOTAL_SIZE_BYTES) {
      setError(`El tamaño de los archivos no puede ser mayor que ${MAX_TOTAL_SIZE_MB} MB.`);
      return;
    }

    setFiles([...files, ...acceptedFiles]);
    setError('');
  };

  const onRemoveFile = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
  };

  const handleSendFiles = async () => {
    if (files.length === 0) {
      setError("No hay archivos para enviar.");
      return;
    }
    setSuccessMessage("Enviando archivos. Espero unos segundos por favor.");
    const res = await EstimateNewNOnboardedBills(files);
    setSuccessMessage("Archivos enviados con éxito.");

    setResult(res.length > 0 ? res[0] : null);

    setFiles([]);
    setError('');
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="container">
      <div className={`fileUploader ${isDragActive ? 'dragOver' : ''}`} {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Suelta aqui las facturas...</p>
        ) : files.length > 0 ? (
          files.map((file, index) => (
            <div key={index} className="icon">
              <button className="removeButton" onClick={() => onRemoveFile(file)}>
                ✖
              </button>
              <span className="fileName">📄 {file.name}</span>
            </div>
          ))
        ) : (
          <p>Arrastra o haz click para subir tu(s) factura(s) de luz</p>
        )}
        {error && <p className="error">{error}</p>}
      </div>
      {files.length > 0 && (
        <button onClick={handleSendFiles} className="sendButton">
          Enviar archivos
        </button>
      )}
      {successMessage && <p className="success">{successMessage}</p>}
      {result && (
        <div className="result">
          <h2>First Result:</h2>
          <pre>{JSON.stringify(result, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default AddNewBills;