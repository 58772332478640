import { ErrorMessage } from 'formik';

import useTranslations from '../../i18n/useTranslations';

import stylesForm from '../Form/Form.module.css';
import styles from './RadioButton.module.css';

const RadioButtonGroup = ({ error, touched, label, children }) => {
  const { t } = useTranslations();

  return (
    <div className={styles.radioButtonGroup}>
      <legend className={styles.radioButtonGroupLegend}>{t(label)}</legend>
      <div className={styles.radioButtonGroupOptions}>{children}</div>
      {touched && error && (
        <ErrorMessage
          render={() => (
            <span className={`${stylesForm.errorMsg} ${stylesForm.noPadding}`}>{t(error)}</span>
          )}
        />
      )}
    </div>
  );
};

export default RadioButtonGroup;
