import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { useDropzone } from 'react-dropzone';

import useTranslations from '../../../../i18n/useTranslations';
import { MAX_TOTAL_SIZE_MB } from '../../../../constants';
import { fileSchema } from '../../../../validationSchemas';

import stylesForm from '../../../Form/Form.module.css';
import styles from './UploadFile.module.css';

const UploadFile = forwardRef(
  ({ name, handleFileUpload, error, touched, onReset, setError, setTouched }, ref) => {
    const [files, setFiles] = useState([]);
    const { t } = useTranslations();

    useImperativeHandle(ref, () => ({
      resetFiles,
    }));

    const onDrop = (acceptedFiles) => {
      fileSchema
        .validate(acceptedFiles)
        .then(() => {
          setFiles(acceptedFiles);
          handleFileUpload({ target: { files: acceptedFiles } });
        })
        .catch((validationError) => {
          setError(validationError.message);
          setTouched({ file: true });
        });
    };

    const onRemoveFile = (fileToRemove, e) => {
      e.stopPropagation();
      const updatedFiles = files.filter((file) => file !== fileToRemove);
      setFiles(updatedFiles);
      handleFileUpload({ target: { files: updatedFiles } });
    };

    const resetFiles = () => {
      setFiles([]);
      handleFileUpload({ target: { files: [] } });
      if (onReset) onReset();
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
    });

    return (
      <div className={styles.root}>
        <div
          className={`${styles.fileUploader} ${isDragActive ? styles.dragOver : ''} ${
            Boolean(files.length) ? styles.hasFiles : ''
          }`}
          {...getRootProps()}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.72205 2.672C2.55005 3.843 2.55005 5.729 2.55005 9.5V13.5C2.55005 17.271 2.55005 19.157 3.72205 20.328C4.89404 21.499 6.77905 21.5 10.5501 21.5H12.55C16.321 21.5 18.207 21.5 19.378 20.328C20.5491 19.156 20.55 17.271 20.55 13.5V9.5C20.55 5.729 20.55 3.843 19.378 2.672C18.2061 1.501 16.321 1.5 12.55 1.5H10.5501C6.77905 1.5 4.89305 1.5 3.72205 2.672ZM7.55005 8.75C7.35114 8.75 7.16037 8.82901 7.01971 8.96968C6.87906 9.11033 6.80005 9.30109 6.80005 9.5C6.80005 9.69892 6.87906 9.88968 7.01971 10.0303C7.16037 10.171 7.35114 10.25 7.55005 10.25H15.55C15.749 10.25 15.9398 10.171 16.0804 10.0303C16.221 9.88968 16.3 9.69892 16.3 9.5C16.3 9.30109 16.221 9.11033 16.0804 8.96968C15.9398 8.82901 15.749 8.75 15.55 8.75H7.55005ZM7.55005 12.75C7.35114 12.75 7.16037 12.829 7.01971 12.9697C6.87906 13.1104 6.80005 13.301 6.80005 13.5C6.80005 13.699 6.87906 13.8896 7.01971 14.0303C7.16037 14.171 7.35114 14.25 7.55005 14.25H12.55C12.749 14.25 12.9398 14.171 13.0804 14.0303C13.221 13.8896 13.3 13.699 13.3 13.5C13.3 13.301 13.221 13.1104 13.0804 12.9697C12.9398 12.829 12.749 12.75 12.55 12.75H7.55005Z"
              fill="currentColor"
            />
          </svg>

          <input name={name} {...getInputProps()} />
          {files.length === 0 ? (
            <p>{t('action:upload')}</p>
          ) : (
            <div className={styles.filesContainer}>
              {files.map((file) => (
                <button
                  key={`${file.name}-${file.path}`}
                  className={styles.removeButton}
                  onClick={(e) => onRemoveFile(file, e)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.11612 17.8838C5.62796 17.3956 5.62796 16.6042 6.11612 16.116L10.2322 11.9999L6.11612 7.88379C5.62796 7.39563 5.62796 6.60418 6.11612 6.11602C6.60427 5.62786 7.39573 5.62786 7.88388 6.11602L12 10.2321L16.1161 6.11602C16.6043 5.62786 17.3957 5.62786 17.8839 6.11602C18.372 6.60418 18.372 7.39563 17.8839 7.88379L13.7678 11.9999L17.8839 16.116C18.372 16.6042 18.372 17.3956 17.8839 17.8838C17.3957 18.3719 16.6043 18.3719 16.1161 17.8838L12 13.7677L7.88388 17.8838C7.39573 18.3719 6.60427 18.3719 6.11612 17.8838Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span className={styles.fileName}>{file.name}</span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={styles.icon}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.7456 5.30633C16.9415 5.50231 17.0516 5.76807 17.0516 6.04519C17.0516 6.3223 16.9415 6.58807 16.7456 6.78405L8.91251 14.6171C8.80899 14.7207 8.68609 14.8028 8.55083 14.8588C8.41557 14.9149 8.27059 14.9437 8.12418 14.9437C7.97777 14.9437 7.83279 14.9149 7.69753 14.8588C7.56227 14.8028 7.43937 14.7207 7.33586 14.6171L3.44405 10.726C3.34423 10.6296 3.26462 10.5143 3.20985 10.3868C3.15507 10.2593 3.12625 10.1222 3.12504 9.9834C3.12383 9.84464 3.15028 9.70702 3.20282 9.57859C3.25537 9.45016 3.33297 9.33347 3.43109 9.23535C3.52922 9.13723 3.6459 9.05963 3.77433 9.00708C3.90277 8.95453 4.04038 8.92809 4.17914 8.9293C4.31791 8.9305 4.45504 8.95933 4.58254 9.0141C4.71004 9.06887 4.82536 9.14849 4.92176 9.2483L8.12383 12.4504L15.2672 5.30633C15.3642 5.20921 15.4795 5.13218 15.6063 5.07961C15.7331 5.02705 15.8691 5 16.0064 5C16.1437 5 16.2796 5.02705 16.4065 5.07961C16.5333 5.13218 16.6485 5.20921 16.7456 5.30633Z"
                      fill="#16B25E"
                    />
                  </svg>
                </button>
              ))}
            </div>
          )}
        </div>
        {error && touched && (
          <div className={stylesForm.errorMsg}>{t(error, { size: MAX_TOTAL_SIZE_MB })}</div>
        )}
      </div>
    );
  }
);

export default UploadFile;
