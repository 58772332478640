import { useNavigate } from "react-router-dom";
import { useDropzone } from 'react-dropzone';

import { ROUTES } from '../../../routes';
import styles from "./AdminOperations.module.css";
import { useAdmin } from '../../../context';
import services from "../../../services";

const {
  getLateLightBills,
  getContractsToOnboard,
  getGeneralStatus,
} = services;

const AdminOperation = () => {
  const { setLightCustomersData, setLightContractsToOnboard, setGeneralStatus } = useAdmin();
  const navigate = useNavigate();

  const handleNextStep = async (e) => {
    e.preventDefault();
    const buttonValue = e.target.value;

    switch (buttonValue) {
      case "button1":
        const res = await getLateLightBills();
        setLightCustomersData(res);
        navigate(`${ROUTES.ADMIN}${ROUTES.LATE_LIGHT_BILLS}`);
        break;
      case "button2":
        const res_2 = await getContractsToOnboard();
        setLightContractsToOnboard(res_2);
        navigate(`${ROUTES.ADMIN}${ROUTES.CONTRACTS_TO_ONBOARD}`);
        break;
      case "button3":
        navigate(`${ROUTES.ADMIN}${ROUTES.ADD_NEW_BILLS}`);
        break;
      case "button4":
        navigate(`${ROUTES.ADMIN}${ROUTES.INVITE_NEW_USERS}`);
        break;
      case "button5":
        const res_5 = await getGeneralStatus();
        setGeneralStatus(res_5);
        navigate(`${ROUTES.ADMIN}${ROUTES.GENERAL_STATUS}`);
        break;
      default:
        console.log("Invalid button");
    }
  };

  return (
    <div className="form-container">
      <div className="headerContainer">
        <h1>¿Qué operacion quiere llevar a cabo?</h1>
      </div>
      <div className={styles.root}>
        <div className={styles.gridButtons}>
          <button
            className={styles.controlButton}
            type="button"
            value="button1"
            onClick={handleNextStep}
          >
            Clientes de luz/gas que no han recibido facturas recientemente
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button2"
            onClick={handleNextStep}
          >
            Contratos que han de darse da alta
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button3"
            onClick={handleNextStep}
          >
            Añadir nuevas facturas
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button4"
            onClick={handleNextStep}
          >
            Invitar a nuevos usuarios
          </button>
          <button
            className={styles.controlButton}
            type="button"
            value="button5"
            onClick={handleNextStep}
          >
            General status
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminOperation;
