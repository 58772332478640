import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useTranslations from '../../../../i18n/useTranslations';

import WarningIcon from './warning-icon.svg';
import styles from './Feedback.module.css';

const FeedbackPropTypes = {
  type: PropTypes.oneOf(['loading', 'error']),
  countdownTime: PropTypes.number,
  text: PropTypes.string,
};

const Feedback = ({ type, countdownTime, text }) => {
  const [countdown, setCountdown] = useState(countdownTime);
  const { t } = useTranslations();

  useEffect(() => {
    if (countdownTime === null || countdownTime === undefined) return;

    setCountdown(countdownTime);

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, [countdownTime]);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {type === 'loading' && <div className={styles.spinner} />}
        {type === 'error' && (
          <img width={48} height={48} src={WarningIcon} alt="" className={styles.error} />
        )}
      </div>
      {text && <p className={styles.text}>{text}</p>}
      {countdownTime !== null && countdownTime !== undefined && (
        <p className={styles.text}>{t('account_address:loading_text', { countdown })}</p>
      )}
    </div>
  );
};

Feedback.propTypes = FeedbackPropTypes;

export default Feedback;
