export const MAX_TOTAL_SIZE_MB = 10;
export const MAX_TOTAL_SIZE_BYTES = MAX_TOTAL_SIZE_MB * 1024 * 1024;

export const LOGIN_FORM_INPUTS = {
  email: '',
  password: '',
};

export const WAITLIST_FORM_INPUTS = {
  email: '',
};

export const PASSWORD_FORM_INPUTS = {
  new_password: '',
  confirm_new_password: '',
};

export const EMAIL_FORM_INPUTS = {
  email: '',
};

export const ACCOUNT_NEW_USER_FORM_INPUTS_PERSON = {
  name: '',
  first_surname: '',
  second_surname: '',
  id_number: '',
  phone_number: '',
  is_privacy_policy_checked: false,
};

export const ACCOUNT_NEW_USER_FORM_INPUTS_COMPANY = {
  ...ACCOUNT_NEW_USER_FORM_INPUTS_PERSON,
  nif: '',
  corporate_name: '',
  office_address: '',
};

export const ACCOUNT_ADDRESS_FORM_INPUTS = {
  address_street: '',
  address_number: '',
  address_stair: '',
  address_floor_number: '',
  address_door_number: '',
  city: '',
  zip_code: '',
  file: [],
  user_is_contract_holder: '',
};
