import { Routes, Route } from 'react-router-dom';

import { ROUTES } from '../../routes';

import { AdminOperations } from './AdminOperations';
import { LateLightBills } from './LateLightBills';
import { UsersToOnboard } from './OnboardUsers';
import { AddNewBills } from './AddNewBills';
import { InviteNewUser } from './InviteNewUser';
import { GeneralStatus } from './GeneralStatus';
import { AdminContextProvider } from '../../context';

const Admin = () => {
  return (
    <AdminContextProvider>
      <Routes>
        <Route path="/" element={<AdminOperations />} />
        <Route path={ROUTES.LATE_LIGHT_BILLS} element={<LateLightBills />} />
        <Route path={ROUTES.CONTRACTS_TO_ONBOARD} element={<UsersToOnboard />} />
        <Route path={ROUTES.ADD_NEW_BILLS} element={<AddNewBills />} />
        <Route path={ROUTES.INVITE_NEW_USERS} element={<InviteNewUser />} />
        <Route path={ROUTES.GENERAL_STATUS} element={<GeneralStatus />} />
      </Routes>
    </AdminContextProvider>
  );
};

export default Admin;
