import useTranslations from '../../i18n/useTranslations';

import { ROUTES } from '../../routes';

import { Button } from '../Button';
import styles from './ErrorPage.module.css';

const ErrorPage = () => {
  const { t } = useTranslations();
  return (
    <article className={styles.root}>
      <div className={styles.content}>
        <header className={styles.header}>
          <h1 className={styles.title}>{t('error:title')}</h1>
          <p className={styles.description}>{t('error:description')}</p>
        </header>
        <div className={styles.body}>
          <Button href={ROUTES.ROOT}>{t('action:go_to_homepage')}</Button>
        </div>
      </div>
    </article>
  );
};

export default ErrorPage;
