import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AppIntlProvider from './i18n/AppIntlProvider';
import { ROUTES } from './routes';
import { AuthProvider } from './context';

import { PublicLayout, PrivateLayout } from './Layout';
import { ErrorPage, OnboardingRoute, PrivateRoute, Onboarding, Admin } from './components';

import './index.css';

const App = () => (
  <AppIntlProvider>
    <AuthProvider>
      <Router>
        <Routes>
          <Route
            path={ROUTES.LOGIN}
            element={
              <PublicLayout>
                <h1>LOGIN</h1>
              </PublicLayout>
            }
          />

          <Route
            path={ROUTES.ROOT}
            element={
              <OnboardingRoute>
                <Onboarding />
              </OnboardingRoute>
            }
          />

          <Route
            path={ROUTES.DASHBOARD}
            element={
              <PrivateRoute>
                <PrivateLayout />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.ADMIN_OPS}
            element={
              <PublicLayout>
                <Admin />
              </PublicLayout>
            }
          />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  </AppIntlProvider>
);

export default App;
