import { useNavigate } from 'react-router-dom';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';
import { Button } from '../../Button';
import services from '../../../services';
import { useForm } from '../../../context';

import ZenviArrowIcon from '../../../assets/zenvi-arrow.svg';

import { Stepper } from '../Stepper';
import styles from './AccountEstimatedResults.module.css';

const { isIBANRegistered } = services;

const AccountEstimatedResults = () => {
  const { formData, updateFormData } = useForm();
  const navigate = useNavigate();
  const { contractResult } = useForm();
  const { t } = useTranslations();

  const handleNextStep = async (e) => {
    e.preventDefault();
    try {
      const res = await isIBANRegistered(formData);
      if (res.success) {
        navigate(ROUTES.ACCOUNT_SUCCESS);
      } else {
        navigate(ROUTES.ACCOUNT_IBAN);
      }
    } catch (err) {}
  };

  return (
    <Stepper currentStep={3}>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('account_estimated_results:heading')}</h1>
        </header>
        <div className={styles.content}>
          <form onSubmit={handleNextStep}>
            <div className={styles.container}>
              {contractResult &&
                contractResult.map((contract) => (
                  <div className={styles.card} key={contract.cups}>
                    {contract.supplier_name && (
                      <div
                        className={styles.providerText}
                      >{`Con el proveedor ${contract.supplier_name}`}</div>
                    )}
                    <div className={styles.cardContent}>
                      <div className={styles.cardSection}>
                        <span>Dirección</span>
                        <h2>{contract.address}</h2>
                      </div>
                      <img
                        width={48}
                        height={48}
                        className={styles.zenviArrowIcon}
                        src={ZenviArrowIcon}
                        alt="Zenvi Arrow Logo"
                      />
                      <div className={styles.cardSection}>
                        <span>{t('account_estimated_results:estimated_savings')}</span>
                        <h2>{contract.ahorro_estimado_anual}€/año</h2>
                      </div>
                    </div>
                  </div>
                ))}
              <div className={styles.buttonContainer}>
                <Button isFullWidth type="submit">
                  {t('login:send')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </Stepper>
  );
};

export default AccountEstimatedResults;
