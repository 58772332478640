import { useEffect, useState } from 'react';
import { TABLET_QUERY } from './mediaqueries';

export default function useMatchMedia(mediaQuery = TABLET_QUERY) {
  const [isMatching, setIsMatching] = useState(false);

  useEffect(() => {
    const handleMatchMedia = () => setIsMatching(window.matchMedia(mediaQuery).matches);

    handleMatchMedia();

    const matchMedia = window.matchMedia(mediaQuery);
    matchMedia.addEventListener('change', handleMatchMedia);

    return () => {
      matchMedia.removeEventListener('change', handleMatchMedia);
    };
  }, [mediaQuery]);

  return isMatching;
}
