import { Navigate } from 'react-router-dom';

import { ROUTES } from '../routes';
import { useAuth, FormProvider } from '../context';

const OnboardingRoute = ({ children }) => {
  const { isAuthenticated, registrationComplete } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={ROUTES.DASHBOARD} replace />;
  }

  if (registrationComplete) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  return <FormProvider>{children}</FormProvider>;
};

export default OnboardingRoute;
