import { useNavigate } from 'react-router-dom';

import useTranslations from '../../../i18n/useTranslations';

import useMatchMedia from '../../useMatchMedia';
import { TABLET_QUERY } from '../../mediaqueries';

import styles from './Stepper.module.css';

const STEPS = [
  {
    label: 'general:stepper:step_one:label',
    value: 1,
  },
  {
    label: 'general:stepper:step_two:label',
    value: 2,
  },
  {
    label: 'general:stepper:step_three:label',
    value: 3,
  },
  {
    label: 'general:stepper:step_four:label',
    value: 4,
  },
];

const StepperHeader = ({ currentStep, steps }) => {
  const { t } = useTranslations();
  return (
    <ol className={styles.headerList}>
      {steps.map((step) => (
        <li
          key={step.value}
          className={`${styles.headerItem} ${
            currentStep === step.value
              ? styles.headerItemActive
              : currentStep > step.value
              ? styles.headerItemCompleted
              : ''
          }`}
        >
          <span className={styles.headerItemValue}>{step.value}</span>
          <span>{t(step.label)}</span>
        </li>
      ))}
    </ol>
  );
};

const StepperHeaderMobile = ({ heading, closeButtonLabel, handleClose }) => {
  return (
    <>
      <p className={styles.headerText}>{heading}</p>
      <button className={styles.headerButton} onClick={handleClose} aria-label={closeButtonLabel}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.11612 17.8838C5.62796 17.3956 5.62796 16.6042 6.11612 16.116L10.2322 11.9999L6.11612 7.88379C5.62796 7.39563 5.62796 6.60418 6.11612 6.11602C6.60427 5.62786 7.39573 5.62786 7.88388 6.11602L12 10.2321L16.1161 6.11602C16.6043 5.62786 17.3957 5.62786 17.8839 6.11602C18.372 6.60418 18.372 7.39563 17.8839 7.88379L13.7678 11.9999L17.8839 16.116C18.372 16.6042 18.372 17.3956 17.8839 17.8838C17.3957 18.3719 16.6043 18.3719 16.1161 17.8838L12 13.7677L7.88388 17.8838C7.39573 18.3719 6.60427 18.3719 6.11612 17.8838Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </>
  );
};

const Stepper = ({ currentStep = 1, children }) => {
  const navigate = useNavigate();
  const { t } = useTranslations();

  const isTablet = useMatchMedia(TABLET_QUERY);

  const handleClose = () => {
    navigate('/');
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {isTablet ? (
          <StepperHeader currentStep={currentStep} steps={STEPS} />
        ) : (
          <StepperHeaderMobile
            heading={t('general:stepper:step_mobile', {
              currentStep,
              totalSteps: STEPS.length,
            })}
            closeButtonLabel={t('general:close')}
            handleClose={handleClose}
          />
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Stepper;
